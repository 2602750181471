const navigation = {
    main: [
        { name: 'Staffing', href: '/staffing' },
        // { name: 'Dental', href: 'https://platform.janiehealth.com' },
        { name: 'Pricing', href: '/pricing' },
        { name: 'About', href: '/about' },
    ]
}

export default navigation;
