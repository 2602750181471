import { useEffect } from 'react';

export default function CalendarEmbed() {
    useEffect(() => {
        const scriptId = 'hs-meetings-embed';
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
            script.async = true;
            script.id = scriptId;
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/brigham-sharp?embed=true"></div>
    );
}